import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const GlobalStyle = createGlobalStyle`
  main {
    background: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const SCContainer = styled.div`
    display: flex;
    font-size: 26rem;
    flex-direction: column;
    width: 100%;
    padding: var(--container-padding);
    text-align: center;

    p {
        font-size: 2rem;
        color: white;
        margin-bottom: 30px;
    }

    a {
        font-size: 0;
    }
`
const SCNotFound = styled.svg`
    #g-usetag {
        stroke-width: 6;
        stroke: #f15f5f;
        animation: gstrokeanim 2s infinite linear;
    }

    @keyframes gstrokeanim {
        0% {
            stroke-width: 6;
        }
        50% {
            stroke-width: 0;
        }
        100% {
            stroke-width: 6;
        }
    }

    .text,
    .text4,
    .text0 {
        fill: none;
        stroke-width: 3;
        stroke-linejoin: round;
        stroke-dasharray: 70 330;
        stroke-dashoffset: 0;
        animation: stroke 10s infinite linear;
    }

    .text:nth-child(5n + 1) {
        stroke: #f15f5f;
        animation-delay: -1.2s;
    }

    .text:nth-child(5n + 2) {
        stroke: #102f4e;
        animation-delay: -2.4s;
    }

    .text:nth-child(5n + 3) {
        stroke: #fff;
        animation-delay: -3.6s;
    }

    .text:nth-child(5n + 4) {
        stroke: #4b8ac9;
        animation-delay: -4.8s;
    }

    .text:nth-child(5n + 5) {
        stroke: #4b8ac9;
        animation-delay: -6s;
    }

    .text0:nth-child(5n + 1) {
        stroke: #f15f5f;
        animation-delay: -1.2s;
    }

    .text0:nth-child(5n + 2) {
        stroke: #102f4e;
        animation-delay: -2.4s;
    }

    .text0:nth-child(5n + 3) {
        stroke: #fff;
        animation-delay: -3.6s;
    }

    .text0:nth-child(5n + 4) {
        stroke: #fff;
        animation-delay: -3.6s;
    }

    .text0:nth-child(5n + 5) {
        stroke: #fff;
        animation-delay: -3.6s;
    }

    .text4:nth-child(5n + 1) {
        stroke: #f15f5f;
        animation-delay: -1.2s;
    }

    .text4:nth-child(5n + 2) {
        stroke: #102f4e;
        animation-delay: -2.4s;
    }

    .text4:nth-child(5n + 3) {
        stroke: #fff;
        animation-delay: -3.6s;
    }

    .text4:nth-child(5n + 4) {
        stroke: #4b8ac9;
        animation-delay: -4.8s;
    }

    .text4:nth-child(5n + 5) {
        stroke: #4b8ac9;
        animation-delay: -6s;
    }

    @-webkit-keyframes stroke {
        100% {
            stroke-dashoffset: -400;
        }
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: -400;
        }
    }
`

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <GlobalStyle />
        <SCContainer>
            <SCNotFound viewBox="0 25 1220 300">
                <symbol id="s-text">
                    <text textAnchor="middle" x="35%" y="50%" dy=".35em">
                        4
                    </text>
                </symbol>
                <symbol id="o-text">
                    <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                        0
                    </text>
                </symbol>
                <symbol id="g-text">
                    <text textAnchor="middle" x="65%" y="50%" dy=".35em">
                        4
                    </text>
                </symbol>
                <use xlinkHref="#s-text" className="text"></use>
                <use xlinkHref="#s-text" className="text"></use>
                <use xlinkHref="#s-text" className="text"></use>
                <use xlinkHref="#s-text" className="text"></use>
                <use xlinkHref="#s-text" className="text"></use>
                <use xlinkHref="#o-text" className="text0"></use>
                <use xlinkHref="#o-text" className="text0"></use>
                <use xlinkHref="#o-text" className="text0"></use>
                <use xlinkHref="#o-text" className="text0"></use>
                <use xlinkHref="#o-text" className="text0"></use>
                <use xlinkHref="#g-text" className="text4"></use>
                <use xlinkHref="#g-text" className="text4"></use>
                <use xlinkHref="#g-text" className="text4"></use>
                <use xlinkHref="#g-text" className="text4"></use>
                <use xlinkHref="#g-text" className="text4"></use>
            </SCNotFound>
            <p>page not found...</p>
            <Button text="Home" to="/" />
        </SCContainer>
    </Layout>
)

export default NotFoundPage
